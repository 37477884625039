$(function() {



    // $(".order .order__selectmenu").selectmenu({
    //     placeholder: " ",
    //     create: function( event, ui ) {
    //         $(this).val("");
    //         console.log(event);
    //         $(".ui-menu").mCustomScrollbar({
    //             scrollInertia: 500,
    //             theme: "dark"
    //         });
    //     },
    //     open: function( event, ui ) {
    //
    //     }
    // });
    // $(".order .order__selectmenu").selectmenu({
    //     placeholder: " ",
    //     create: function( event, ui ) {
    //         $(this).val("");
    //     },
    //     open: function( event, ui ) {
    //         $(".ui-menu").each(function() {
    //             setTimeout(function() {
    //                 $(this).mCustomScrollbar({
    //                     scrollInertia: 500,
    //                     theme: "dark"
    //                 });
    //             }, 100);
    //         });
    //         // $(".ui-menu").mCustomScrollbar({
    //         //     scrollInertia: 500,
    //         //     theme: "dark"
    //         // });
    //     },
    //     close: function( event, ui ) {
    //         $(".ui-menu").each(function() {
    //             $(this).mCustomScrollbar("destroy");
    //         });
    //     }
    // });



    //catalog
    // $(".c-header-filter select[name=age]").selectmenu({
    //     placeholder: "Edad",
    //     create: function( event, ui ) {
    //         $(this).val("");
    //     },
    //     open: function( event, ui ) {
    //         $(".ui-menu").mCustomScrollbar({
    //             scrollInertia: 500,
    //             theme: "dark"
    //         });
    //     },
    // });
    // $(".c-header-filter select[name=weight]").selectmenu({
    //     placeholder: "Peso",
    //     create: function( event, ui ) {
    //         $(this).val("");
    //     },
    //     open: function( event, ui ) {
    //         $(".ui-menu").mCustomScrollbar({
    //             scrollInertia: 500,
    //             theme: "dark"
    //         });
    //     },
    // });
    // $(".c-header-filter select[name=type]").selectmenu({
    //     placeholder: "Tipo de collar que busco",
    //     create: function( event, ui ) {
    //         $(this).val("");
    //     },
    //     open: function( event, ui ) {
    //         $(".ui-menu").mCustomScrollbar({
    //             scrollInertia: 500,
    //             theme: "dark"
    //         });
    //     },
    //     close: function( event, ui ) {
    //         $(".ui-menu").mCustomScrollbar("destroy");
    //     }
    // });

    // $("select[name=select-payment]").selectmenu({
    //     open: function( event, ui ) {
    //
    //     }
    // });

    // $(".input__card");
    // $(".input__card").keyup(function () {
    //     console.log(this.value.length);
    //     console.log($(this).val());
    //     if (this.value.length > 3) {
    //         // this.value = this.value.slice(0,4);
    //         return false;
    //     }
    // });

    //credit-card
    $(".input__card").keypress(function (e) {
        if (e.which != 8 && e.which != 0 && e.which != 46 && (e.which < 48 || e.which > 57) ){
            return false;
        }else if ( $(this).val().length > 2 ) {
            if ( $(this).next().length && $(this).val().length < 4 ) {
                $(this).next().focus();
                console.log("next");
            }else if ( $(this).val().length > 3 ) {
                return false;
            }
        }
    });

    $("form[name=form-payment]").validate({
        onfocusout: false,
        onkeyup: false,
        rules: {
            "user-tel": {
                minlength: 15,//должно идти первым
                checkMask: true,
            },
            "user-name": {
                errorPlacement: function(error, element) {
                    $("label.error").removeClass("no-show");
                    setTimeout(function() {
                        $("label.error").addClass("no-show");
                    }, 1000);
                }
            },
            "mes" : {
                minlength: 1,
            }
        },
        messages: {
            "user-name" : {
                required: "Вы забыли ввести ваше имя",
            },
            "user-tel" : {
                required: "Вы забыли ввести телефон",
                minlength: "Введите 10 цифр"
            },
            "mes" : {
                required: "Введите сообщение",
            }
        }
    });

    //catalog
    $(".select__dropdown").mCustomScrollbar({
        scrollInertia: 500,
        theme: "dark"
    });

});