$(document).ready(function(){
    var submitIcon = $('.searchbox-icon');
    var inputBox = $('.searchbox-input');
    var searchBox = $('.searchbox');
    var searchLabel = $(".searchbox-label");
    var isOpen = false;
    submitIcon.click(function(){
        if(isOpen == false){
            searchBox.addClass('searchbox-open');
            inputBox.focus();
            searchLabel.addClass("active");
            isOpen = true;
        } else {
            searchBox.removeClass('searchbox-open');
            inputBox.focusout();
            searchLabel.removeClass("active");
            isOpen = false;
        }
    });
    submitIcon.mouseup(function(){
        return false;
    });
    searchBox.mouseup(function(){
        return false;
    });
    $(document).mouseup(function(){
        if(isOpen == true){
            $('.searchbox-icon').css('display','block');
            submitIcon.click();
        }
    });

    var hamburgerMobile = $(".hamburger--mobile");
    var mobileNav = $(".header__mobile-nav");
    var myOverlay = $(".my-overlay");
    hamburgerMobile.click(function() {
       $(this).toggleClass("active");
        mobileNav.toggleClass("active");
        $("body").toggleClass("no-scroll");
        if ( mobileNav.hasClass("active") ) {
            myOverlay.fadeIn();
            // $("body").css({
            //    "overflow-y": "hidden"
            // });
            // $("body").addClass("no-scroll");
        }else{
            myOverlay.fadeOut();
            // $("body").removeAttr("style");
        }
    });
    myOverlay.click(function() {
        hamburgerMobile.removeClass("active");
        mobileNav.removeClass("active");
        $(this).fadeOut();
        $("body").removeClass("no-scroll");
    });

});
function buttonUp(){
    console.log("buttonUp");
    var inputVal = $('.searchbox-input').val();
    inputVal = $.trim(inputVal).length;
    if( inputVal !== 0){
        // $('.searchbox-icon').css('display','none');
    } else {
        $('.searchbox-input').val('');
        $('.searchbox-icon').css('display','block');
    }
}
