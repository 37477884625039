$(function() {

    // $(".catalog__item-rate").each(function() {
    //    var myRate = $(this).data("rate");
    //    $(this).rateYo({
    //        rating: myRate,
    //        readOnly: true,
    //        ratedFill: "#facb6a"
    //    });
    // });
    $(".catalog__item-rate").each(function() {
        var rate = $(this).data("rate");
        $(this).find("svg").slice(0,rate).addClass("active");
    });

    //show more in catalog
    var catalogItems;
    if ( $(".catalog").length ) {
        catalogItems = $(".catalog__item");
        if ( $(window).width() < 640 ) {
            catalogItems.slice(5).addClass("mobile-hide");
        }
        $(window).resize(function() {
            if ( $(window).width() < 640 ) {
                catalogItems.slice(5).addClass("mobile-hide");
            }
        });
    }
    $(".catalog__more").click(function() {
        catalogItems.removeClass("mobile-hide");
        $(this).hide();
    });

});