$(function() {

    if ( $(".basket__table").length ) {

        var basketItems = $(".basket__item"),
            basketTotalNum = $(".basket__total-num span"),
            basketTotalPrice = $(".basket__total-price span"),
            num = 0,
            price = 0;

        //первоначальный подсчет суммы для каждого товара в таблице
        //old
        // basketItems.each(function() {
        //     var currentPrice = +$(this).data("price");
        //
        //     // var digitalNum = +$(this).find(".basket__num span").text();
        //     var digitalNum = +$(this).find(".select__main").text().split(" ")[0];
        //     var price = currentPrice * digitalNum;
        //     $(this).find(".basket__price span").text(price.toFixed(3));
        // });
        //new
        basketItems.each(function() {
            var currentPrice = +$(this).data("price").replace('.', '').replace(',', '.');
            // console.log(currentPrice)

            // var digitalNum = +$(this).find(".basket__num span").text();
            var digitalNum = +$(this).find(".select__main").text().split(" ")[0];
            // var price = currentPrice * digitalNum;
            var price = parseFloat((currentPrice * digitalNum).toFixed(2));
            var priceTemp = price.toString().split(".");
            if ( priceTemp.length > 1 ) {
                $(this).find(".basket__price span").text( number_format(priceTemp[0], 0, ',', '.') + "," + priceTemp[1]);
            }else{
                $(this).find(".basket__price span").text( number_format(priceTemp[0], 0, ',', '.'));
            }

        });
        //ф-ция подсчета общей суммы и кол-ва товаров
        //old
       // var score = function () {
       //     num = 0;
       //     price = 0;
       //     basketItems.each(function() {
       //         // var digitalNum = +$(this).find(".basket__num span").text();
       //         var digitalNum = +$(this).find(".select__main").text().split(" ")[0];
       //         var currentPrice = +$(this).data("price");
       //         price += digitalNum * currentPrice;
       //         num += digitalNum;
       //     });
       //
       //     basketTotalNum.text(num);
       //     basketTotalPrice.text(price.toFixed(3));
       //  };
        //new
        var score = function () {
            num = 0;
            price = 0;
            basketItems.each(function() {
                // var digitalNum = +$(this).find(".basket__num span").text();
                var digitalNum = +$(this).find(".select__main").text().split(" ")[0];
                var currentPrice = +$(this).data("price").replace('.', '').replace(',', '.');
                // console.log(currentPrice)
                var priceTemp = parseFloat((digitalNum * currentPrice).toFixed(2));
                price += priceTemp;
                price = parseFloat(price.toFixed(2));
                // console.log(priceTemp, price)
                num += digitalNum;
            });

            basketTotalNum.text(num);
            var priceArray = price.toString().split(".");
            if ( priceArray.length > 1 ) {
                basketTotalPrice.text( number_format(priceArray[0], 0, ',', '.') + "," + priceArray[1]);
            }else{
                basketTotalPrice.text( number_format(priceArray[0], 0, ',', '.'));
            }
            // basketTotalPrice.text(price);
        };
        score();

        // $(".basket__num-plus").click(function() {
        //     var item = $(this).closest(".basket__item");
        //     var currentNum = $(this).prev().find("span");
        //     var currentNumDigital = +currentNum.text().replace(/\s/ig, '');
        //     var currentPrice = +item.data("price");
        //
        //
        //     currentNumDigital++;
        //     currentNum.text(currentNumDigital);
        //     var digitalNum = +item.find(".basket__num span").text();
        //     var price = currentPrice * digitalNum;
        //     item.find(".basket__price span").text(price.toFixed(3));
        //
        //     score();
        // });
        //
        // $(".basket__num-minus").click(function() {
        //     var item = $(this).closest(".basket__item");
        //     var currentNum = $(this).next().find("span");
        //     var currentNumDigital = +currentNum.text().replace(/\s/ig, '');
        //     var currentPrice = +item.data("price");
        //
        //     if ( currentNumDigital === 1 ) {
        //         return false;
        //     }else{
        //         currentNumDigital--;
        //         currentNum.text(currentNumDigital);
        //         var digitalNum = +item.find(".basket__num span").text();
        //         var price = currentPrice * digitalNum;
        //         item.find(".basket__price span").text(price.toFixed(3));
        //
        //         score();
        //     }
        // });

        //old
        // $(".basket-select").find(".select__item").click(function() {
        //     var selectNum = +$(this).closest(".select").find(".select__main").text().split(" ")[0];
        //     var item = $(this).closest(".basket__item");
        //     var currentPrice = +item.data("price");
        //     var price = currentPrice * selectNum;
        //     item.find(".basket__price span").text(price.toFixed(3));
        //
        //     score();
        // });
        //new
        $(".basket-select").find(".select__item").click(function() {
            var selectNum = +$(this).closest(".select").find(".select__main").text().split(" ")[0];
            var item = $(this).closest(".basket__item");
            var currentPrice = +item.data("price").replace('.', '').replace(',', '.');

            // var price = currentPrice * selectNum;
            var price = parseFloat((currentPrice * selectNum).toFixed(2));
            var priceArray = price.toString().split(".");
            if ( priceArray.length > 1 ) {
                item.find(".basket__price span").text( number_format(priceArray[0], 0, ',', '.') + "," + priceArray[1]);
            }else{
                item.find(".basket__price span").text( number_format(priceArray[0], 0, ',', '.'));
            }

            score();
        });

        $(".basket__more-text").click(function() {
            basketItems.addClass("visible");
            $(this).parent().hide();
        });

    }


});