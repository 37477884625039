$(function() {

    // закоментить в плагине chosen.js
    // if (/iP(od|hone)/i.test(window.navigator.userAgent) || /IEMobile/i.test(window.navigator.userAgent) || /Windows Phone/i.test(window.navigator.userAgent) || /BlackBerry/i.test(window.navigator.userAgent) || /BB10/i.test(window.navigator.userAgent) || /Android.*Mobile/i.test(window.navigator.userAgent)) {
    //   return false;
    // }

    if ( $(".ordering-pay").length || $(".thx__content").length ) {
        $(".main-content").addClass("flex-center");
    }

    $('input[type="tel"]').mask("+56 9 99999999");
    // $('input[type="tel"]').inputmask("56 9 99999999");
    // $('input[type="tel"]').inputmask("regex: \"[0-9]*");
    //jquery-ui placeholder
    // $.widget( 'app.selectmenu', $.ui.selectmenu, {
    //     _drawButton: function() {
    //         this._super();
    //         var selected = this.element
    //                 .find( '[selected]' )
    //                 .length,
    //             placeholder = this.options.placeholder;
    //
    //         if (!selected && placeholder) {
    //             this.buttonItem.text(placeholder);
    //         }
    //     }
    // });

    $(".select").click(function() {
        // $(this).hasClass('active') ? $(".select").removeClass("active") : $(".select").removeClass("active"),$(this).addClass("active");
        if ( $(this).hasClass("active") ) {
            $(".select").removeClass("active");
        }else{
            $(".select").removeClass("active");
            $(this).addClass("active");
        }
    });
    $(".select__item").click(function() {
        var text = $(this).text();
        $(this).siblings(".select__item").removeClass("active");
        $(this).addClass("active");
        var select = $(this).closest(".select");
        select.removeClass("error");
        select.find("input").val(text);
        select.find(".select__main").text(text);
        select.find(".select__main").removeClass("mobile").addClass("full");
        console.log($(this).closest(".select").find("input").val());
    });

    $(document).mouseup(function (e){ // событие клика по веб-документу
        var div = $(".select");
        if ( !div.is(e.target) && div.has(e.target).length === 0 ) {
            div.removeClass("active");
        }
    });

});

// $(function() {

    // new WOW().init();

    // $('input[type="tel"]').mask("+7(999) 999-99-99");



    // var radioValue = number_format($(this).val(), 0, ',', ' ');
    function number_format(number, decimals, dec_point, thousands_sep) {
        number = (number + '').replace(/[^0-9+\-Ee.]/g, '');
        var n = !isFinite(+number) ? 0 : +number,
            prec = !isFinite(+decimals) ? 0 : Math.abs(decimals),
            sep = (typeof thousands_sep === 'undefined') ? ',' : thousands_sep,
            dec = (typeof dec_point === 'undefined') ? '.' : dec_point,
            s = '',
            toFixedFix = function(n, prec) {
                var k = Math.pow(10, prec);
                return '' + (Math.round(n * k) / k)
                    .toFixed(prec);
            };
        // Fix for IE parseFloat(0.55).toFixed(0) = 0;
        s = (prec ? toFixedFix(n, prec) : '' + Math.round(n))
            .split('.');
        if (s[0].length > 3) {
            s[0] = s[0].replace(/\B(?=(?:\d{3})+(?!\d))/g, sep);
        }
        if ((s[1] || '')
            .length < prec) {
            s[1] = s[1] || '';
            s[1] += new Array(prec - s[1].length + 1)
                .join('0');
        }
        return s.join(dec);
    }

// });