$(function() {

    $(".c-header__filter").click(function(e) {
        e.preventDefault();
       $(this).toggleClass("active");
    });

    $(".c-header__inner-btn").click(function() {
        $(this).hide();
        $(".c-header-filter").css({
            "display" : "flex"
        });
    });

    if ( $(".c-header-filter").length ) {
        var selectMain = $(".select__main");
        // if ($(window).width()< 480) {
        //     $(".select-block").each(function() {
        //        $(this).find(".selectmenu").data("placeholder", "111 ").removeAttr("data");
        //        console.log("place");
        //     });
        // }
        $(window).resize(function() {
            if ( $(window).width() < 480 ) {
                selectMain.each(function() {
                    if ( $(this).hasClass("full") ) {

                    }else{
                        $(this).addClass("mobile");
                    }
                });
            }else{
                selectMain.removeClass("mobile");
            }
        });
    }

    $("form[name=form-info-dog]").submit(function() {
        var flag = true;
        // $(this).find("input").each(function() {
        //     if ( !$(this).val() ) {
        //         $(this).parent().addClass("error");
        //         console.log("empty");
        //         flag = false;
        //         return false;
        //     }else{
        //         $(this).parent().removeClass("error");
        //     }
        // });
        $(".selectmenu").each(function() {

            if ( !$(this).val() ) {
                // console.log($(this).val())
                $(this).next().addClass("error");
                flag = false;
                return false;
            } else{
                $(this).next().removeClass("error");
            }
        });
        if (!flag) {
            return false;
        }
    });

});