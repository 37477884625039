$(function() {

    // var productAside = $(".p-aside__rate");
    // if ( productAside.length ) {
    //     var productAsideRate = productAside.data("rate");
    //     productAside.find("svg").slice(0,productAsideRate).addClass("active");
    // }

    if ( $(".p-aside__rate").length ) {
        var rate = $(".p-aside__rate").data("rate");
        var inputRate = $("input[name=rate]");
        $(".p-aside__rate").rateYo({
            normalFill: "#cfd7e0",
            ratedFill: "#facb6a",
            starWidth: "18px",
            spacing: "3px",
            "rating" : rate,
            "starSvg":"<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'><path d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/ ></svg>",
            onSet: function (rating, rateYoInstance) {
                // console.log("Rating is set to: " + rating);
                inputRate.val(rating);
                console.log(inputRate.val());
            }
        });
    }
    if ( $(".p-aside__absent-rate").length ) {
        var rateA = $(".p-aside__absent-rate").data("rate");
        $(".p-aside__absent-rate").rateYo({
            readOnly: true,
            normalFill: "#cfd7e0",
            ratedFill: "#facb6a",
            starWidth: "18px",
            spacing: "3px",
            "rating" : rateA,
            "starSvg":"<svg xmlns='http://www.w3.org/2000/svg' width='18' height='18' viewBox='0 0 24 24'><path d='M12 .587l3.668 7.568 8.332 1.151-6.064 5.828 1.48 8.279-7.416-3.967-7.417 3.967 1.481-8.279-6.064-5.828 8.332-1.151z'/ ></svg>"
        });
    }

    $("form[name=form-product]").submit(function() {
        var flag = true;
        // $(this).find(".input-product").each(function() {
        //     if ( !$(this).val() ) {
        //         $(this).parent().addClass("error");
        //         console.log("empty");
        //         flag = false;
        //         return false;
        //     }else{
        //         $(this).parent().removeClass("error");
        //     }
        // });
        // if (!flag) {
        //     return false;
        // }

        $(".selectmenu").each(function() {

           if ( !$(this).val() ) {
               // console.log($(this).val())
               $(this).next().addClass("error");
               flag = false;
               return false;
           } else{
               $(this).next().removeClass("error");
           }
        });
        if (!flag) {
            return false;
        }

    });

    $(".selectmenu").chosen({
        disable_search_threshold: 10,
        no_results_text: "Oops, nothing found!",
        width: "100%"
    });
    // setTimeout(function() {
    //     $(".chosen-results").mCustomScrollbar({
    //         scrollInertia: 500,
    //         theme: "dark"
    //     });
    // }, 1000);

    $('.selectmenu').on('change', function(evt, params) {
        $(this).siblings(".chosen-container").removeClass("error");
        // $(this).trigger('chosen:updated');
    });
    // var ff = true;


    // $('.selectmenu').on('chosen:showing_dropdown', function(evt, params) {
    //
    // });
    $(".chosen-results").mCustomScrollbar({
        scrollInertia: 500,
        theme: "dark"
    });

    if ( $(".form__num-block").length ) {
        var total = $(".form__total-price");
        var dataPrice = $(".product-wrap").data("price");
        var dataPriceReplace = +$(".product-wrap").data("price").replace('.', '').replace(',', '.');
        var inputPrice = $("input[name=price]");
        total.text(dataPrice);
        inputPrice.val(dataPrice);

        var minus = $(".form__num-minus");
        var plus = $(".form__num-plus");
        var num = $(".form__num");
        var inputNumber = $(".form__num-block").find("input");
        var numDigital = +num.text();
        plus.click(function() {
            numDigital++;
            num.text(numDigital);
            inputNumber.val(numDigital);

            var totalNum = +$(".form__total-price").text().replace('.', '').replace(',', '.');//текущий total

            var price = parseFloat((totalNum + dataPriceReplace).toFixed(2));//новое число для total
            console.log(totalNum, dataPriceReplace)
            var priceTemp = price.toString().split(".");
            if ( priceTemp.length > 1 ) {
                total.text( number_format(priceTemp[0], 0, ',', '.') + "," + priceTemp[1]);
            }else{
                total.text( number_format(priceTemp[0], 0, ',', '.'));
            }
        });
        minus.click(function() {
           if (numDigital < 2) {
               return false;
           }else{
               numDigital--;
               num.text(numDigital);
               inputNumber.val(numDigital);

               var totalNum = +$(".form__total-price").text().replace('.', '').replace(',', '.');//текущий total

               var price = parseFloat((totalNum - dataPriceReplace).toFixed(2));//новое число для total
               console.log(totalNum, dataPriceReplace)
               var priceTemp = price.toString().split(".");
               if ( priceTemp.length > 1 ) {
                   total.text( number_format(priceTemp[0], 0, ',', '.') + "," + priceTemp[1]);
               }else{
                   total.text( number_format(priceTemp[0], 0, ',', '.'));
               }
           }
        });
    }

    if ( $(".product-absent").length ) {
        var total = $(".form__total-price");
        var dataPrice = $(".product-wrap").data("price");
        total.text(dataPrice);
    }


});