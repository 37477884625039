$(function() {

    // $(".footer__policy").click(function(e) {
    //     e.preventDefault();
    //     var thisBtn = $(this);


    // });
// $(".header__hamburger-text").click(function(e) {
//     e.preventDefault();
    if ( $(".modal").length ) {
        setTimeout(function() {
            $.magnificPopup.open({
                items: {
                    src: '#modal-welcome', // can be a HTML string, jQuery object, or CSS selector
                    type: 'inline',
                    // midClick: true,
                },
                removalDelay: 400,
                callbacks: {
                    beforeOpen: function () {
                        this.st.mainClass = "mfp-zoom-in";
                    }
                },
            });
        }, 500);
    }


// });



});