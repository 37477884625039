$(function() {

    $(".brends__slider").slick({
        infinite: false,
        arrow: true,
        dots: false,
        slidesToShow: 5,
        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 900,
                settings: {
                    slidesToShow: 3,
                    initialSlide: 1
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                    centerMode: true,
                    centerPadding: "20px",
                    infinite: true,
                    dots: true,
                    arrow: false,
                    initialSlide: 2
                }
            }
        ]
    });

});