$(function() {

    //проверка выбранных селектов
    $("form[name=form-order]").submit(function(e) {
        // e.preventDefault();
        var flag = true;
        // $(this).find("input").each(function() {
        //     if ( !$(this).val() ) {
        //         $(this).parent().addClass("error");
        //         console.log("empty");
        //         flag = false;
        //         return false;
        //     }else{
        //         $(this).parent().removeClass("error");
        //     }
        // });
        // if (!flag) {
        //     return false;
        // }

        $(".selectmenu").each(function() {

            if ( !$(this).val() ) {
                // console.log($(this).val())
                $(this).next().addClass("error");
                flag = false;
                return false;
            } else{
                $(this).next().removeClass("error");
            }
        });
        if (!flag) {
            return false;
        }

    });

});