$(function() {

    var productTabs = $(".product-tabs__inner");
    var productTabsItem = $(".product-tabs__list-item");
    productTabsItem.click(function() {
        productTabsItem.removeClass("active");
        $(this).addClass("active");
        productTabs.hide();
        productTabs.eq($(this).index()).fadeIn();
    });

    $(".product-tabs").find(".select__item").click(function() {
        productTabs.hide();
        productTabs.eq($(this).index()).fadeIn();
    });

});