$(function() {

    var radioPayment = $(".radio-payment"),
        radioDelivery = $(".radio-delivery"),
        radioHowToPay = $(".radio-how-to-pay");
    var labelHome = $(".label-delivery-home"),
        labelHomeInput = labelHome.find("input"),
        labelBranch = $(".label-delivery-branch"),
        labelBranchInput = labelBranch.find("input");
    var radioMiddle = $(".form__radio-middle-wrap");
    var radioDeliveryHome = $(".radio-delivery-home");
    var radioDeliveryBranch = $(".radio-delivery-branch");

    radioPayment.change(function() {
        $(this).closest(".form__radio-top-wrap").find(".form__radio-item").removeClass("active");
       $(this).closest(".form__radio-item").addClass("active");
        if ( $(this).data("delivery") === "branch" ) {
            labelHome.hide();
            labelHomeInput.removeAttr("required");
            labelBranch.show();
            labelBranchInput.attr("required", true);

            radioMiddle.hide();
            radioDeliveryHome.prop("checked", false);
            radioDeliveryBranch.prop("checked", false);
        }else{
            labelBranch.hide();
            labelBranchInput.removeAttr("required");
            labelHome.show();
            labelHomeInput.attr("required", true);
            radioMiddle.show();
            radioDeliveryHome.prop("checked", true);
        }
    });

    radioDelivery.change(function() {
        $(this).closest(".form__radio-middle-wrap").find(".form__radio-item").removeClass("active");
        $(this).closest(".form__radio-item").addClass("active");
        if ( $(this).data("delivery") === "branch" ) {
            labelHome.hide();
            labelHomeInput.removeAttr("required");
            labelBranch.show();
            labelBranchInput.attr("required", true);
        }else{
            labelBranch.hide();
            labelBranchInput.removeAttr("required");
            labelHome.show();
            labelHomeInput.attr("required", true);
        }
    });

    radioHowToPay.change(function() {
        $(this).closest(".form__radio-bottom-wrap").find(".form__radio-item").removeClass("active");
        $(this).closest(".form__radio-item").addClass("active");
    });

    //validation
    jQuery.extend(jQuery.validator.messages, {
        email: "error",
        tel: "error",
        name: "error"
    });
    $("form[name=form-page-order]").validate({
        rules: {
            "email": {

            }
        },
        messages: {
            "email": {
                required: "error"
            },
            "name": {
                required: "error"
            },
            "fathername": {
                required: "error"
            },
            "mothername": {
                required: "error"
            },
            "rut": {
                required: "error"
            },
            "address": {
                required: "error"
            },
            "delivery-branch": {
                required: "error"
            },
            "comuna": {
                required: "error"
            },
            "city": {
                required: "error"
            },
            "tel": {
                required: "error"
            }

        }
    });
    $("form[name=form-contact]").validate({
        messages: {
            "user-name": {
                required: "error"
            },
            "user-email": {
                required: "error"
            },
            "message": {
                required: "error"
            }
        }
    });

});