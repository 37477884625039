$(function() {

    $(".c-aside__main").click(function() {
        $(this).next().slideToggle();
        $(this).toggleClass("active");
    });

    $(".c-aside__weight-block .c-aside__inner").mCustomScrollbar({
        scrollInertia: 500,
        theme: "dark"
    });

    $(".c-aside__material-block .c-aside__inner").mCustomScrollbar({
        scrollInertia: 500,
        theme: "dark"
    });

    $(".c-aside__length-block .c-aside__inner").mCustomScrollbar({
        scrollInertia: 500,
        theme: "dark"
    });

    $(".c-aside__thickness-block .c-aside__inner").mCustomScrollbar({
        scrollInertia: 500,
        theme: "dark"
    });

    var asideFilter = $(".c-aside");
    $(".c-header__inner-filter-btn").click(function() {
        asideFilter.addClass("active");
        $("body").css("overflow", "hidden");

        $(document).mouseup(function (e) {

            if (!asideFilter.is(e.target) // если клик был не по нашему блоку
                && asideFilter.has(e.target).length === 0) { // и не по его дочерним элементам
                asideFilter.removeClass('active'); // скрываем его
                // $("body").removeClass("no-scroll");
                $("body").removeAttr("style");

            }
        });

    });

    $(".c-aside__close").click(function() {
        asideFilter.removeClass('active');
        $("body").removeAttr("style");
    });

});