$(function() {

    $(".m-product__slider").slick({
        centerMode: true,
        arrows: false,
        centerPadding: '67px',
        slidesToShow: 6,
        // variableWidth: true,
        slidesToScroll: 3,
        responsive: [
            {
                breakpoint: 1599,
                settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 5
                }
            },
            {
                breakpoint: 1299,
                settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 1099,
                settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 3
                }
            },
            {
                breakpoint: 899,
                settings: {
                    centerMode: true,
                    centerPadding: '40px',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 767,
                settings: {
                    centerMode: false,
                    centerPadding: '0',
                    slidesToShow: 2
                }
            },
            {
                breakpoint: 599,
                settings: {
                    centerMode: false,
                    // centerPadding: '40px',
                    slidesToShow: 1,
                    dots: true
                }
            }
        ]
    });

    $(".m-product__star").click(function(e) {
        e.preventDefault();
        $(this).toggleClass("active");
    });


});